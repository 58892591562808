.header{
  width: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  right: 0;
  z-index: 100;
  height: 6em;
}

.header-inner {
  display: flex;
  width: 100%;
  max-width: 33em;
  justify-content: flex-end;
  align-items: center;
  margin: 0 4em;
}

.header-inner > a {
  margin-left: 1.5em;
  font-size: 1.2em;
  font-weight: 800;
}

.back-arrow {
  width: 1em;
  padding-top: .8em;
  margin: auto;
  margin-left: 0 !important;
}

@media screen and (max-width: 723px){
  .header {
    width: 100%;
  }
  .header-inner {
    margin: 2em
  }
}