body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif !important;
  text-rendering: geometricPrecision;
}

img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: #cc6666;
  font-weight: bold;
}
