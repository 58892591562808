.thanks-inner {
  /*background: aqua;*/
  height: 100%;
  padding: 0 4em;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
}

.thanks-section {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 33em;
  /*background: yellow;*/
}

@media screen and (max-width: 723px){
  .thanks-inner {
    padding: 0 2em;
  }
}