.App {
  display: flex;
  min-height: 100vh;
  background: #fff;
  overflow: hidden;
}

main {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.brand-side {
  background: #f9f4ee;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.brand-photo {
  width: 17em;
}

.brand-name {
  font-size: 2.7em;
  margin-top: .4em;
  color: #1e1e1e
}

.brand-tagline {
  margin-top: 1.5em;
  font-size: 1em;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.brand-social {
  height: 33px;
  margin-top: 1.5em;
}

.social-container > div > a {
  margin: 0 .5em;
  width: 33px !important;
  height: 33px !important;
}

.content-side {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  overflow: hidden;
  padding: 6em 0 4em 0;
}

.hide {
  display: none;
}

.bold {
  font-weight: 800;
}


@media screen and (max-width: 723px){
  .App {
    flex-flow: column;
  }
  .brand-side {
    width: 100%;
    height: 100vh;
  }
  .brand-photo {
    width: 10em;
  }
  .content-side {
    width: 100%;
    min-height: 80vh;
  }

}