.contact-inner {
  height: 100%;
  padding: 0 4em;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
}

.contact-section {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 33em;
}

.contact-form {
  width: 100%;
  display: flex;
  flex-flow: column;
}

input {
  font-family: 'Montserrat', sans-serif;
  font-size: .95em;
  width: 100%;
  margin-bottom: 1em;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  box-sizing: border-box;
  padding: .3em .5em;
}

textarea {
  font-family: 'Montserrat', sans-serif;
  font-size: .95em;
  height: 10em;
  max-width: 100%;
  min-width: 100%;
  margin-bottom: 1em;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  box-sizing: border-box;
  padding: .3em .5em;
}

button {
  font-family: 'Montserrat', sans-serif;
  color: #f9f4ee;
  font-size: 1.1em;
  width: 9em;
  padding: .7em .5em;
  font-weight: 600;
  background: #cc6666;
  border-radius: 5em;
  border: 0 solid transparent;
}

button:hover {
  cursor: pointer;
}

@media screen and (max-width: 723px){
  .contact-inner {
    padding: 0 2em;
  }
}