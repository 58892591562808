.home-inner {
  height: 100%;
  padding: 0 4em;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
}

.home-section {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 33em;
}

.section-header {
  font-weight: 800;
  font-size: 1.3em;
  color: #c66;
}

.section-content {
  font-size: 1em;
  font-family: 'Cardo', serif;
}

@media screen and (max-width: 723px){
  .home-inner {
    padding: 0 2em 2em 2em;
  }
}
